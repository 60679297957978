//================== [CONSTANT KEYS] ==================
const KEY_AUTH = "key_auth";
const KEY_TOKEN = "key_token";
const KEY_RECENTLY_SEARCH = "key_recently_search";
const KEY_TEMP_RETURN_URL = "KEY_TEMP_RETURN_URL";
const KEY_TEMP_LOCATION_RETURN_URL = "KEY_TEMP_LOCATION_RETURN_URL";
const KEY_TEMP_SUB_RETURN_URL = "KEY_TEMP_SUB_RETURN_URL";
const KEY_PWA_VISITED = "KEY_PWA_VISITED";
const KEY_ACTIVE_WORKSHOP = "KEY_ACTIVE_WORKSHOP";
const KEY_TEMP_ACTIVE_WORKSHOP = "KEY_TEMP_ACTIVE_WORKSHOP";
const KEY_TEMP_WORKSHOP = "KEY_TEMP_WORKSHOP";
const KEY_WARNING_VISITED = "KEY_WARNING_VISITED";
const KEY_VERSION_VISITED = "KEY_VERSION_VISITED";

const ServiceStorage = {
  //!--------------- Temp Return URL
  setTempReturnURL: (pathFromConfigLink: string | null) => {
    if (pathFromConfigLink) {
      localStorage.setItem(KEY_TEMP_RETURN_URL, pathFromConfigLink);
    } else {
      localStorage.removeItem(KEY_TEMP_RETURN_URL);
    }
  },
  getTempReturnURL: (): string | null => {
    return localStorage.getItem(KEY_TEMP_RETURN_URL);
  },
  removeTempReturnURL: (): boolean => {
    localStorage.removeItem(KEY_TEMP_RETURN_URL);
    return true;
  },
  //!--------------- Temp location Return URL
  setTempLocationReturnURL: (pathFromConfigLink: string | null) => {
    if (pathFromConfigLink) {
      localStorage.setItem(KEY_TEMP_LOCATION_RETURN_URL, pathFromConfigLink);
    } else {
      localStorage.removeItem(KEY_TEMP_LOCATION_RETURN_URL);
    }
  },
  getTempLocationReturnURL: (): string | null => {
    return localStorage.getItem(KEY_TEMP_LOCATION_RETURN_URL);
  },
  removeTempLocationReturnURL: (): boolean => {
    localStorage.removeItem(KEY_TEMP_LOCATION_RETURN_URL);
    return true;
  },
  //!--------------- Temp subscription Return URL
  setTempSubReturnURL: (pathFromConfigLink: string | null) => {
    if (pathFromConfigLink) {
      localStorage.setItem(KEY_TEMP_SUB_RETURN_URL, pathFromConfigLink);
    } else {
      localStorage.removeItem(KEY_TEMP_SUB_RETURN_URL);
    }
  },
  getTempSubReturnURL: (): string | null => {
    return localStorage.getItem(KEY_TEMP_SUB_RETURN_URL);
  },
  removeTempSubReturnURL: (): boolean => {
    localStorage.removeItem(KEY_TEMP_SUB_RETURN_URL);
    return true;
  },
  //!--------------- Auth
  setAuth: (auth: any) => {
    localStorage.setItem(KEY_AUTH, JSON.stringify(auth));
  },
  getAuth: (): any | null => {
    return JSON.parse(localStorage.getItem(KEY_AUTH) as string);
  },
  removeAuth: (): boolean => {
    localStorage.removeItem(KEY_AUTH);
    return true;
  },
  setToken: (token: string) => {
    localStorage.setItem(KEY_TOKEN, token);
  },
  getToken: (): string | null => {
    return localStorage.getItem(KEY_TOKEN) as string;
  },
  removeToken: (): boolean => {
    localStorage.removeItem(KEY_TOKEN);
    return true;
  },
  //------------- recently searches
  setRecentlySearches: (items: any[]) => {
    localStorage.setItem(KEY_RECENTLY_SEARCH, JSON.stringify(items));
  },
  getRecentlySearches: (): any[] | null => {
    return JSON.parse(localStorage.getItem(KEY_RECENTLY_SEARCH) as string);
  },
  removeRecentlySearches: (): boolean => {
    localStorage.removeItem(KEY_RECENTLY_SEARCH);
    return true;
  },
  //------------- pwa modal
  setPWAVisitStatus: () => {
    localStorage.setItem(KEY_PWA_VISITED, "true");
  },
  getPWAVisitStatus: (): string | null => {
    return localStorage.getItem(KEY_PWA_VISITED);
  },
  //------------- active workshop
  setActiveWorkshop: (id: string) => {
    localStorage.setItem(KEY_ACTIVE_WORKSHOP, id);
  },
  getActiveWorkshop: (): string | null => {
    return localStorage.getItem(KEY_ACTIVE_WORKSHOP);
  },
  removeActiveWorkshop: (): void => {
    localStorage.removeItem(KEY_ACTIVE_WORKSHOP);
  },
  //------------- temp workshop
  setTempActiveWorkshop: (id: string) => {
    localStorage.setItem(KEY_TEMP_ACTIVE_WORKSHOP, id);
  },
  getTempActiveWorkshop: (): string | null => {
    return localStorage.getItem(KEY_TEMP_ACTIVE_WORKSHOP);
  },
  removeTempActiveWorkshop: (): void => {
    localStorage.removeItem(KEY_TEMP_ACTIVE_WORKSHOP);
  },
  //------------- save temp workshop
  setTempWorkshop: (data: string) => {
    localStorage.setItem(KEY_TEMP_WORKSHOP, data);
  },
  getTempWorkshop: (): string | null => {
    return localStorage.getItem(KEY_TEMP_WORKSHOP);
  },
  removeTempWorkshop: (): void => {
    localStorage.removeItem(KEY_TEMP_WORKSHOP);
  },
  setWarningVisited: () => {
    localStorage.setItem(KEY_WARNING_VISITED, "true");
  },
  getWarningVisited: (): string | null => {
    return localStorage.getItem(KEY_WARNING_VISITED);
  },
  removeWarningVisited: (): void => {
    localStorage.removeItem(KEY_WARNING_VISITED);
  },

  setVersionVisited: (version: string) => {
    localStorage.setItem(KEY_VERSION_VISITED, version);
  },
  getVersionVisited: (): string | null => {
    return localStorage.getItem(KEY_VERSION_VISITED);
  },
  removeVersionVisited: (): void => {
    localStorage.removeItem(KEY_VERSION_VISITED);
  },
};

export { ServiceStorage };
