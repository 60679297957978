import Axios from "axios";
import { ConfigLink } from "core/routes";
import { vState } from "core/valtio";
import NProgress from "nprogress";
import { useNotification } from "ui/components";
import { configs } from "../../configs";
import { keycloak } from "../../keycloak";
import { ServiceStorage } from "../services";

NProgress.configure({ showSpinner: false });

const env = import.meta.env;

interface ConfigType {
  contentType?: string;
  auth?: boolean;
}

/**
 * Set axios config and handle refresh token
 * @param {object} config - Passed config
 *
 * @return {void}
 */
// export const init = (config: ConfigType = {}) => {
//     const {
//       contentType = 'application/x-www-form-urlencoded',
//       auth = false,
//     } = config;

//     axios.defaults.headers.common['Content-Type'] = contentType;

//     if (auth) {
//       const accessToken =  ServiceStorage.getToken();
//       if (accessToken) {
//         axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//       }
//     }
// };

export const axios = Axios.create({
  baseURL: configs().endpointUrl,
});

axios.interceptors.request.use(
  (config) => {
    // const savedToken = ServiceStorage.getToken();
    // let accessToken:null | string = null;

    // if(keycloak.authenticated && keycloak.token) {
    //   accessToken = keycloak.token
    // } else if (savedToken) {
    //   accessToken = savedToken;
    // }

    // if (accessToken) {
    //   config.headers = {
    //     ...(config.headers || {}),
    //     Authorization: `Bearer ${accessToken}`,
    //   };
    // }

    if (keycloak.authenticated && keycloak.idToken) {
      const activeWorkshop = ServiceStorage.getActiveWorkshop();
      const activeTempWorkshop = ServiceStorage.getTempActiveWorkshop();

      // console.log(activeTempWorkshop ? "activeTempWorkshop" : activeWorkshop ? "activeWorkshop" : "Null");

      config.headers = {
        ...(config.headers || {}),
        Authorization: `Bearer ${keycloak.idToken}`,
        "x-workshop": activeTempWorkshop ? activeTempWorkshop : activeWorkshop ? activeWorkshop : "",
      };
    }
    NProgress.start();
    return config;
  },
  (e) => Promise.reject(e),
);

axios.interceptors.response.use(
  (res) => {
    NProgress.done();
    return res;
  },
  (err) => {
    NProgress.done();
    if (err.response.status === 401) {
      console.log("401");
      const savedToken = ServiceStorage.getToken();
      if (keycloak.idToken && keycloak.idToken !== savedToken) {
        console.log("SAVED", savedToken);
        console.log("KEYCLOAK", keycloak.idToken);

        ServiceStorage.setToken(keycloak.idToken);
        err.config.headers["Authorization"] = `Bearer ${keycloak.idToken}`;
        axios.request(err.config);
      } else {
        useNotification("لطفا دوباره وارد شوید.", "warning", "اتمام توکن");
        vState.isAuthenticated = false;
        vState.userInfo = {
          id: "",
          name: "",
          username: "",
          phoneNumber: "",
          roles: [],
          groups: [],
          premium: null,
          mobile: "",
          userProfileId: "",
          avatar: "",
        };
        ServiceStorage.removeAuth();
        ServiceStorage.removeToken();
        if (window.location.pathname !== ConfigLink.profile) {
          localStorage.setItem("saved_url", `${window.location.href}${window.location.search}`);
        }
        keycloak.logout();
        window.location.href = ConfigLink.intro;
      }
    } else if (err.response.status === 403) {
      console.warn("unauthorized access");
      vState.unauthorizedAccess = true;
    }
    return Promise.reject(err);
  },
);
