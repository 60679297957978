/* eslint-disable */
import { axios } from "./api";

const baseUrl = "/premiums";
const subscription = {
  getPackages: async () => {
    const url = `${baseUrl}/packages`;
    const result = await axios.get(url);

    return result;
  },
  selectPackage: async (id: string) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, { package: id });

    return result;
  },
  getPackageInfo: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
};
export { subscription };
