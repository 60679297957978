/* eslint-disable */
import { ConfigLink, ServiceStorage, tools, useQueryString } from "core";
import React, { useState } from "react";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import { MdChevronLeft, MdOutlineAccessTimeFilled } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { NoRegisteredCars } from "ui/views/Home/components";
import { Text, useNotification } from "../../../components";

interface IBulkSmsTabDataProps {
  data: any[];
}

const BulkSmsTabData: React.FC<IBulkSmsTabDataProps> = React.memo(({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState("");

  const paySubHandler = async (paymentId) => {
    if (paymentId) {
      ServiceStorage.setTempSubReturnURL(`${location.pathname}${location.search}`);
      setLoading(paymentId);
      tools.redirectToPaymentPage(paymentId);
      setLoading("");
    } else {
      useNotification(
        "اطلاعات پرداخت یافت نشد. لطفا مجددا صفحه را بارگذاری کنید و در صورت رفع نشدن مشکل با پشتیبانی تماس بگیرید.",
        "error",
      );
    }
  };

  const clickHandler = (item) => {
    if (item.status === "created" || item.status === "payment_failed") {
      paySubHandler(item.paymentId);
    } else {
      navigate({ pathname: ConfigLink.bulkSmsDetail, search: useQueryString({ id: item.id }) });
    }
  };

  if (data.length !== 0) {
    return (
      <>
        {data.map((item) => {
          const statusColor =
            item.status === "sent"
              ? "ts-success"
              : item.status === "rejected" || item.status === "payment_failed"
              ? "ts-secondary-red"
              : "ts-warning";
          // item.status === 'payment_succeeded' || item.status === 'approved' || item.status === 'created' ?
          return (
            <div
              key={item.id}
              onClick={() => clickHandler(item)}
              className="w-full flex justify-between items-center cursor-pointer ts-shadow mb-3 rounded-2xl p-3"
            >
              <span className="flex flex-col justify-start items-start">
                <Text type="H6" className="ts-primary mb-2">
                  {item.title || "بدون عنوان"}
                </Text>
                <Text type="P4" className="ts-gray-07 mb-2">
                  گیرندگان: <b className="ts-primary ts-text-p4">{item.contacts.length} مشتری</b>
                </Text>
                <Text type="P5" className="ts-accent ts-ltr-dir mb-2">
                  {item.createdAt ? tools.getSimpleDate(item.createdAt, true) : "---"}
                </Text>

                <span className="flex justify-center items-center">
                  {item.status === "sent" ? (
                    <IoIosCheckmarkCircle size={16} className="ts-success" />
                  ) : item.status === "rejected" || item.status === "payment_failed" ? (
                    <IoIosCloseCircle size={16} className="ts-secondary-red" />
                  ) : (
                    <MdOutlineAccessTimeFilled size={16} className="ts-warning -mt-0.5" />
                  )}
                  <Text type="H6" className={`${statusColor} mr-1 pt-px`}>
                    {item.status === "sent"
                      ? "ارسال شده"
                      : item.status === "rejected"
                      ? "رد شده"
                      : item.status === "payment_succeeded" || item.status === "approved"
                      ? "در صف انتشار"
                      : item.status === "created"
                      ? "در انتظار پرداخت"
                      : "پرداخت ناموفق"}
                  </Text>
                </span>
              </span>

              {loading === item.paymentId ? (
                <Loader size="sm" className="ml-2" />
              ) : (
                <MdChevronLeft size={28} className="ts-accent" />
              )}
            </div>
          );
        })}
      </>
    );
  } else {
    return <NoRegisteredCars note="داده ای یافت نشد." />;
  }
});

export { BulkSmsTabData };

const styles = {};
