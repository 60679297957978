/* eslint-disable */
import qs from "qs";
import { axios } from "./api";

interface IReminder {
  expiresInKilometer?: number;
  expiresInDays?: number;
  service: string;
  expiresInDate?: string;
  description?: string;
}

const baseUrl = "/sessions";
const sessions = {
  setSession: async (kilometer: number, plate: string, workshop: string) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, { kilometer, plate, workshop });

    return result;
  },
  getSession: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
  getAllSessions: async (query?: string) => {
    const url = `${baseUrl}${query ? query : ""}`;
    const result = await axios.get(url);
    return result;
  },
  completeSession: async (id: string) => {
    const url = `${baseUrl}/${id}/complete`;
    const result = await axios.post(url);

    return result;
  },
  removeSession: async (id: string, updateKilometer: boolean) => {
    const url = `${baseUrl}/${id}?updateKilometer=${updateKilometer}`;
    const result = await axios.delete(url);

    return result;
  },
  addReminder: async (id: string, data: IReminder) => {
    const url = `${baseUrl}/${id}/reminders`;
    // init({auth: true});
    const result /*:Promise<IRootResponse<IUserInfo>>*/ = await axios.post(url, data);
    return result;
  },
  updateReminder: async (id: string, reminderId, data: IReminder) => {
    const url = `${baseUrl}/${id}/reminders/${reminderId}`;
    const result = await axios.put(url, data);
    return result;
  },
  // sort: "kilometer" | "expiry_date" = "kilometer"
  getReminders: async (
    id: string,
    page: number,
    type: "history" | "session" = "session",
    sessionId?: string | null,
  ) => {
    const url =
      type === "history"
        ? `/plates/${id}/reminders?pageSize=50&page=${page}${sessionId ? `&sessionId=${sessionId}` : ""}`
        : `/sessions/${id}/reminders?pageSize=55&page=${page}`;

    const result = await axios.get(url);

    return result;
  },
  getRecommendationService: async (id: string) => {
    const url = `/ui/services/${id}/recommendations`;

    const result = await axios.get(url);

    return result;
  },
  getClientsList: async (page: number, sort: "DATE" | "NAME" | null, query: string | null) => {
    const url = `/contacts`;
    const result = await axios.get(url, {
      params: {
        pageSize: 50,
        page,
        ...(query ? { query } : undefined),
        ...(sort ? { sort } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  getClientDetail: async (id: string) => {
    const url = `/contacts/${id}`;
    const result = await axios.get(url);

    return result;
  },
  addClientToList: async (data: { contactName: string; mobile: string; userProfileId: string }) => {
    const url = `/contacts`;
    const result = await axios.post(url, data);

    return result;
  },
  inviteClient: async (data: { contactName: string; mobile: string }) => {
    const url = `/contacts/invite`;
    const result = await axios.post(url, data);

    return result;
  },
  editClient: async (id: string, data: { contactName: string; mobile: string }) => {
    const url = `/plates/${id}/contact`;
    const result = await axios.put(url, data);

    return result;
  },
  editClientInList: async (id: string, data: { contactName: string; mobile: string }) => {
    const url = `/contacts/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  getClientSession: async (id: string, page: number) => {
    const url = `/sessions?userProfileId=${id}`;
    const result = await axios.get(url, {
      params: {
        pageSize: 50,
        page,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  getSessionHistory: async (id: string) => {
    const url = `/plates/${id}/info`;
    const result = await axios.get(url);

    return result;
  },
};
export { sessions };
