/* eslint-disable */
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, useChangeTitle, vState } from "../../../core";
import { ContainerScroll, SelectCity, Text, useNotification } from "../../components";

const AccountSetting = () => {
  const metaTags = useChangeTitle("ویراژ - تنظیمات");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = useState(true);
  const [notifLoading, setNotifLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [isSecureLoading, setSecureLoading] = useState(false);
  const [city, setCity] = useState("");
  const [cityModal, setCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState<any | null>(null);
  const [hideMyNumber, setHideMyNumber] = useState<boolean>(false);

  const getCities = async (cityId) => {
    const citiesList = await ApiInstance.common.getAllCities();
    const selectedCityFilter = citiesList.filter((item) => item.value === cityId);

    if (selectedCityFilter.length !== 0) {
      setSelectedCity(selectedCityFilter[0]);
    }
  };

  const getSetting = async () => {
    await ApiInstance.account
      .getUserSetting()
      .then((res) => {
        console.log(res);
        const data = res.data;
        setCity(data.cityId || "");
        setHideMyNumber(data.hideMyNumber || false);
        if (data.cityId) {
          getCities(data.cityId || "");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getSetting();
  }, []);

  const backClickHandler = () => {
    navigate(".");
  };

  const requestHandler = async (value, type: 2 | 3) => {
    type === 2 ? setCityLoading(true) : setSecureLoading(true);
    await ApiInstance.account
      .setUserSetting({
        ...(type === 2 ? { cityId: value } : undefined),
        ...(type === 3 ? { hideMyNumber: value } : undefined),
      })
      .then((res) => {
        console.log("res", res);
        useNotification(
          type === 2 ? "شهر محل سکونت شما ثبت شد." : "تغییرات درخواستی ثبت شد.",
          "success",
          "تنظیمات",
          2000,
        );
        type === 2 ? setCity(value) : setHideMyNumber((prev) => !prev);
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "تنظیمات");
      })
      .finally(() => {
        type === 2 ? setCityLoading(false) : setSecureLoading(false);
      });
  };

  const selectCityHandler = (city) => {
    setSelectedCity(city);
    setCity(city.value);
    requestHandler(city.value, 2);
  };

  const secureNumberHandler = async (value) => {
    console.log(value);

    requestHandler(value, 3);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H4" className="ts-accent mb-6">
          تنظیمات
        </Text>

        {/* <Text type="H5" className="ts-accent mb-2">
          امنیت تماس
        </Text>
        <Text type="P5" className="ts-gray-07 mb-4">
          در صورتیکه تمایل ندارید شماره تماس شما به سایر کاربران نمایش داده شود، می توانید آن را مخفی کنید.
        </Text>

        <div className="w-full flex justify-start items-center mb-6">
          <Toggle
            size="sm"
            className="ts-secure-toggle"
            disabled={pageLoading}
            loading={isSecureLoading}
            checked={hideMyNumber}
            onChange={(ch) => secureNumberHandler(ch)}
          />
          <Text type="P4" className="ts-accent mr-2">
            عدم نمایش شماره همراه
          </Text>
        </div> */}

        <Text type="H5" className="ts-accent mb-2">
          ناحیه جغرافیایی
        </Text>
        <Text type="P5" className="ts-gray-07 mb-2">
          برای خدمت رسانی بهتر و متناسب با ناحیه جغرافیایی شما خواهشمندیم شهر محل زندگی خود را وارد نمایید.
        </Text>
        <Text type="H6" className="ts-accent mb-2">
          شهر
        </Text>
        <div className="w-full relative mb-6">
          {pageLoading ? (
            <Skeleton containerClassName="w-full" className="w-full h-12 rounded-xl" />
          ) : (
            <div
              onClick={() => setCityModal(true)}
              className="w-full cursor-pointer rounded-xl p-4 flex justify-between items-center h-12 ts-input-border-city"
            >
              <Text type="P4" className="ts-accent">
                {selectedCity ? selectedCity.label : "انتخاب شهر"}
              </Text>
              <BiChevronDown size={21} className="ts-accent -mt-px" />
            </div>
          )}
          {cityLoading && (
            <div className="bg-white absolute top-4 left-4 z-30">
              <Loader size="xs" />
            </div>
          )}
        </div>
      </ContainerScroll>
      <SelectCity
        isOpen={cityModal}
        selectedId={city.length !== 0 ? city : selectedCity ? selectedCity?.value : null}
        onClose={() => setCityModal(false)}
        onSelect={(city) => selectCityHandler(city)}
      />
    </>
  );
};

export { AccountSetting as default };
