/* eslint-disable */
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import customers from "../../../../assets/images/sub-customers.png";
// import limit from "../../../../assets/images/sub-limit.png";
import manage from "../../../../assets/images/sub-manage.png";
// import priority from "../../../../assets/images/sub-priority.png";
import { ConfigLink, ServiceStorage, vState } from "../../../../core";
import { useChangeTitle } from "../../../../core/hooks";
import { Button, ContainerScroll, Text } from "../../../components";

const SubInfo = ({}) => {
  const metaTags = useChangeTitle("ویراژ - سرویس ویژه");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  const returnUrl = ServiceStorage.getTempSubReturnURL();

  const advantages = [
    {
      id: 0,
      icon: customers,
      title: "ارتباط با مشتریان",
      description: "می توانید لیست مشتریان خود را ببینید و با آنها ارتباط ( پیام/ تماس) برقرار کنید.",
    },
    {
      id: 1,
      icon: manage,
      title: "مدیریت چند کسب و کار",
      description: "می‌توانید به صورت نامحدود کسب و کار خودرویی خود را تعریف کنیدو از امکانات ویراژ استفاده کنید.",
    },
    // {
    //   id: 2,
    //   icon: priority,
    //   title: "نمایش کسب‌و‌کار در بالای لیست",
    //   description:
    //     "پس از جستجوی مالکین خودرو برای پیدا کردن کسب و کار، مشترکین ویژه ابتدای لیست نمایش قرار خواهند گرفت.",
    // },
    // {
    //   id: 3,
    //   icon: limit,
    //   title: "رفع محدودیت ها",
    //   description: "تمامی محدودیت‌ها (تعداد قطعات فروشگاه و ...) پس از فعالسازی بسته ویژه برداشته خواهند شد.",
    // },
  ];

  const viewSubHandler = () => {
    navigate(ConfigLink.compareSub);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={138} NoSticky className="pt-56 relative">
        <Text type="H3" className="ts-accent mb-4">
          سرویس ویژه ویراژ
        </Text>
        <Text type="P4" className="ts-accent mb-6">
          با فعالسازی سرویس طلایی، علاوه بر رفع محدودیت ها، امکانات اضافی برای شما فعال خواهد شد.
        </Text>
        {advantages.map((item) => {
          return (
            <div key={item.id} className="w-full flex flex-col justify-start items-start mb-6">
              <span className="flex justify-center items-center mb-1">
                <img src={item.icon} alt={item.id.toString()} />
                <Text type="H6" className="ts-gray-08 mr-2">
                  {item.title}
                </Text>
              </span>
              <Text type="P4" className="ts-gray-07">
                {item.description}
              </Text>
            </div>
          );
        })}

        <div className="w-full flex flex-col justify-center items-center bg-white fixed bottom-0 left-0 px-6 ts-sub-sticky-button">
          <Button
            onClick={viewSubHandler}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full max-w-7xl"
            className="w-full rounded-xl h-12 mb-2 mt-2"
          >
            مشاهده سرویس ویژه
          </Button>

          <Button
            onClick={() => navigate(ConfigLink.manageWorkshops, { replace: true })}
            bcolor="transparent"
            textType="H6"
            classNameContainer="w-full max-w-7xl"
            className="w-full rounded-xl h-12 ts-primary"
          >
            تمایلی ندارم
          </Button>
        </div>
      </ContainerScroll>
    </>
  );
};

export { SubInfo as default };
