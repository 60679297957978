/* eslint-disable */
import { useEffect, useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Divider, Loader } from "rsuite";
import { useSnapshot } from "valtio";
import logo from "../../../../assets/images/logo-xl.png";
import { ConfigLink, ServiceStorage, tools, vState } from "../../../../core";
import { useChangeTitle, useFetchQueryString, useQueryString } from "../../../../core/hooks";
import { Button, ContainerScroll, Text, useNotification } from "../../../components";

type PremiumPackage = "OneMonth" | "SixMonth" | "OneYear";

interface IQueryStringTransfer {
  status: "success" | "payment_failed" | "verification_failed";
  packageName?: PremiumPackage | "bulk_sms";
  trackingCode?: string;
  factorNumber?: number;
  transactionId?: string;
  amount?: string;
}

const PaymentStatus = ({}) => {
  const metaTags = useChangeTitle("ویراژ - وضعیت پرداخت");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let { status, packageName, trackingCode, factorNumber, transactionId, amount } =
    useFetchQueryString<IQueryStringTransfer>();
  const returnUrl = ServiceStorage.getTempSubReturnURL();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const backHandler = () => {
    if (returnUrl) {
      ServiceStorage.removeTempSubReturnURL();
      navigate(
        {
          pathname: returnUrl,
          search: status === "success" ? useQueryString({ subPayed: "1" }) : "",
        },
        { replace: true },
      );
    } else {
      if (packageName && packageName === "bulk_sms") {
        navigate(
          {
            pathname: ConfigLink.workshopClients,
            search: status === "success" ? useQueryString({ subPayed: "1" }) : "",
          },
          { replace: true },
        );
      } else {
        navigate(
          {
            pathname: ConfigLink.manageWorkshops,
            search: status === "success" ? useQueryString({ subPayed: "1" }) : "",
          },
          { replace: true },
        );
      }
    }
  };

  const copyHandler = () => {
    if (transactionId) {
      navigator.clipboard.writeText(transactionId);
      useNotification("کد رهگیری کپی شد", "info");
    }
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} NoSticky className="relative pt-0">
        <img src={logo} alt="logo" className="ts-primary my-28 mx-auto" />
        <div
          className={`w-full ${
            loading ? "ts-receipt-height" : ""
          } ts-shadow rounded-xl p-6 flex flex-col justify-center items-center`}
        >
          {loading ? (
            <Loader center className="mt-28 ts-primary" />
          ) : (
            <>
              <Text type="H4" className={`${status === "success" ? "ts-success" : "ts-warning"} mb-6`}>
                {status === "success" ? "پرداخت موفق" : "پرداخت ناموفق"}
              </Text>
              {status === "success" ? (
                <>
                  <Text type="H6" className="ts-accent mb-2">
                    رسید پرداخت آنلاین:
                  </Text>
                  <Text type="H5" className="ts-success mb-4">
                    {amount ? `${tools.thousandSeparator(+amount / 10)} تومان` : "---"}
                  </Text>
                  <Text type="P4" className="ts-accent">
                    از طریق کارت بانکی شما انجام شده است.
                  </Text>
                </>
              ) : (
                <>
                  <Text type="P4" className="ts-accent mb-4">
                    پرداخت ناموفق بود. در صورت کسر شدن وجه، مبلغ طی ۷۲ ساعت به حساب شما برگشت داده خواهد شد.
                  </Text>
                  <Text type="H6" className="ts-accent mb-2">
                    کد پیگیری:
                  </Text>
                  <span onClick={copyHandler} className="flex justify-center items-center cursor-pointer">
                    {transactionId && <MdOutlineContentCopy size={19} className="ts-gray-05 -mt-0.5" />}
                    <Text type="H5" className="ts-gray-07 mr-2">
                      {transactionId || "کد پیگیری یافت نشد"}
                    </Text>
                  </span>
                </>
              )}
              <Divider className="w-full ts-bg-gray-02 m-0 my-4" />

              <Text type="P4" className="ts-gray-07 mb-6">
                تاریخ: {tools.convertDateToPersianDate(new Date().toISOString(), true)}
              </Text>
              <Button
                onClick={backHandler}
                bcolor={status === "success" ? "primary" : "black"}
                textType="H6"
                classNameContainer="w-full"
                className="w-full rounded-xl h-12"
              >
                بازگشت به ویراژ
              </Button>
            </>
          )}
        </div>
      </ContainerScroll>
    </>
  );
};

export { PaymentStatus as default };
