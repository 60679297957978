/* eslint-disable */
import qs from "qs";
import { axios } from "./api";

const baseUrl = "/bulksms";

interface IInfo {
  payload: string;
  contactIds: string[];
}

const bulkSms = {
  createBulkSms: async (data: IInfo) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, data);

    return result;
  },
  getBulkFactor: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
  getPaymentUrl: async (data: { paymentId: string; callbackUrl?: string }) => {
    const url = `${baseUrl}/payment`;
    const result = await axios.post(url, data);

    return result;
  },
  getHistory: async (page: number, sort: "DATE" | "NAME" | null) => {
    const url = `${baseUrl}`;
    const result = await axios.get(url, {
      params: {
        pageSize: 50,
        page,
        ...(sort ? { sort } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  getHistoryDetail: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
};

export { bulkSms };
