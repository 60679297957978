/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigApp, ConfigLink, ServiceStorage, tools, useChangeTitle, vState } from "../../../core";
import {
  ContainerScroll,
  DesktopTitle,
  NoWorkspaceEmptyState,
  UpdateLogModal,
  useNotification,
  VpnAlert,
} from "../../components";
import {
  Advertisement,
  CompleteInfoAlert,
  HomepageLoader,
  MostUsedServices,
  PwaModal,
  SearchPages,
  WorkshopMainCard,
} from "./components";

interface IHomePageProps {}

const HomePage: React.FC<IHomePageProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - داشبورد");
  const snap = useSnapshot(vState);

  const workshopSearch = snap.activeFeatures.includes("workshop_search");
  const shopSearch = snap.activeFeatures.includes("shop_search");
  const partSearch = snap.activeFeatures.includes("part_order");
  const equipmentSearch = snap.activeFeatures.includes("equipment_search");

  const admission = snap.activeFeatures.includes("admission_discharge");
  const shop = snap.activeFeatures.includes("shop");
  const contacts = snap.activeFeatures.includes("contact_list");
  const partList = snap.activeFeatures.includes("part_order_list");

  const [loading, setLoading] = useState(true);
  const [userCity, setUserCity] = useState("");
  const [statistics, setStatistics] = useState(null);
  const [cityLoading, setCityLoading] = useState(true);
  const [workspaceRegistered, setWorkshopRegistered] = useState(false);
  const [pwaModal, setPwaModal] = useState(false);
  const [visitablePopup, setVisitablePopup] = useState(true);
  const [versionModal, setVersionModal] = useState(false);
  const [vpnEnabled, setVpnEnabled] = useState(false);

  const getWorkshop = async () => {
    await ApiInstance.workshop
      .getWorkshop()
      .then((result) => {
        console.log(result);
        if (result.data.data.length === 0) {
          console.log("No Workshop");
          vState.userWorkspaces = [];
          setWorkshopRegistered(false);
        } else {
          console.log("Workshop OK", result);
          setWorkshopRegistered(true);
          vState.userWorkspaces = result.data.data;
          tools.setActiveWorkshop(result.data.data);
        }
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لظفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getStatistics = async () => {
    await ApiInstance.workshop
      .getStatisticsGrowth(`per_week`)
      .then((res) => {
        console.log("stats", res);
        setStatistics(res.data);
      })
      .catch((err) => console.log("err stats", err));
  };

  const getUserCity = async () => {
    await ApiInstance.account
      .getUserSetting()
      .then((res) => {
        console.log("userCity", res);
        setUserCity(res.data.cityId || "");
      })
      .catch((err) => {
        console.log("userCity", err);
      })
      .finally(() => {
        setCityLoading(false);
      });
  };

  useEffect(() => {
    getUserCity();
    getWorkshop();

    const pwaVisited = !!ServiceStorage.getPWAVisitStatus();
    const isIOS = tools.isIOS();
    const isRunningStandalone = ConfigApp.isRunningStandalone();

    if (isIOS && !pwaVisited && !isRunningStandalone) {
      setPwaModal(!isRunningStandalone);
    }

    const warningVisited = ServiceStorage.getWarningVisited();
    if (warningVisited) {
      setVisitablePopup(false);
    } else {
      setVisitablePopup(true);
    }

    const url = window.location.pathname;
    if (url === ConfigLink.dashboard || url === ConfigLink.homePage) {
      getUserIp();
    }
  }, []);

  useEffect(() => {
    if (snap.activeWorkshop) {
      getStatistics();
    }
  }, [snap.activeWorkshop]);

  useEffect(() => {
    const versionVisited = ServiceStorage.getVersionVisited();
    if (versionVisited) {
      const savedVersion = +snap.version.number.toString().replaceAll(".", "");
      if (savedVersion > +versionVisited) {
        setVersionModal(true);
      } else {
        setVersionModal(false);
      }
    } else {
      if (snap.version.number !== 0) {
        setVersionModal(true);
      }
    }
  }, [snap.version]);

  const getUserLocation = async (ip) => {
    await ApiInstance.common
      .getUserLocation(ip)
      .then((res) => {
        console.log("location", res);
        if (res.status === 200 && res.data.countryCode !== "IR") {
          setVpnEnabled(true);
        }
      })
      .catch((err) => console.log("err location", err));
  };

  const getUserIp = async () => {
    await ApiInstance.common
      .getUserIp()
      .then((res) => {
        console.log("ip", res);
        getUserLocation(res.data.ip || "");
      })
      .catch((err) => console.log("err IP", err));
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className={`relative overflow-x-hidden`}>
        <DesktopTitle title="خانه" />
        {loading ? (
          <HomepageLoader />
        ) : (
          <>
            {visitablePopup &&
              !cityLoading &&
              (userCity.length === 0 ||
                !("name" in snap.userInfo) ||
                ("name" in snap.userInfo && !Boolean(snap.userInfo.name))) && <CompleteInfoAlert />}
            {workspaceRegistered ? (
              <>
                <WorkshopMainCard statistics={statistics} />
                {(admission || shop || contacts || partList) && <MostUsedServices />}
                {(workshopSearch || shopSearch || partSearch || equipmentSearch) && <SearchPages />}
              </>
            ) : (
              <NoWorkspaceEmptyState returnUrl={ConfigLink.homePage} />
            )}
          </>
        )}
        {!loading && <Advertisement />}
      </ContainerScroll>

      <PwaModal isOpen={pwaModal} onClose={() => setPwaModal(false)} />

      <UpdateLogModal isOpen={versionModal} onClose={() => setVersionModal(false)} />
      <VpnAlert isOpen={vpnEnabled} onClose={() => setVpnEnabled(false)} />
    </>
  );
});

export { HomePage as default };

const styles = {};
