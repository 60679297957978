/* eslint-disable */
import { ApiInstance, useChangeTitle, useFetchQueryString, vState } from "core";
import { useAuth } from "hooks";
import { useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { Button, ContainerScroll, Text } from "ui/components";
import { useSnapshot } from "valtio";
import { AddOrEditUserModal, DeleteUserModal, UserCard } from "../components";

const env = import.meta.env;

type UserType = "active" | "pending";
type AccessType = "owner" | "admin" | "agent";

interface IQueryStringTransfer {
  id: string;
  name: string;
  access: AccessType;
}

const ManageWorkshopStaffs = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - مدیریت کارکنان");
  let { id, name, access } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [SMSloading, setSMSLoading] = useState(false);

  const [userModal, setUserModal] = useState(false);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userModalType, setUserModalType] = useState<"add" | "edit">("add");
  const [userType, setUserType] = useState<UserType>("pending");
  const [ownerInfo, setOwnerInfo] = useState(ownerDefault);

  const getUsers = async (pageIncreased: boolean, pageNumber?: number) => {
    await ApiInstance.agents
      .getAgents(pageNumber || pageCount + 1)
      .then((res) => {
        console.log("users", res);
        setCount(res.data.meta.total);
        setPageCount((prev) => prev + 1);
        const usersList = res.data.data.filter(
          (item) => item.user && item.user.id !== snap.userInfo.id && item.accessLevel !== "owner",
        );

        if (pageIncreased) {
          vState.users = [...snap.users, ...usersList];
        } else {
          vState.users = usersList;
          setOwnerInfo(res.data.data.filter((item) => item.accessLevel === "owner")[0] || ownerDefault);
        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        getUsersInvitations();
      });
  };
  const getUsersInvitations = async () => {
    await ApiInstance.agents
      .getAgentsInvitations()
      .then((res) => {
        console.log("invitations", res);
        vState.usersInvitations = res.data.data.filter((item) => item.status !== "accepted");
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUsers(false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > snap.users.length + 1 && !loading) {
      getUsers(true);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const addUserHandler = () => {
    setUserModalType("add");
    setUserModal(true);
  };

  const getFormedUser = (user) => {
    const data = {
      accessLevel: user.accessLevel,
      id: user.id,
      mobile: user.user.phoneNumber || "",
      name: user.user.name || "",
    };
    return data;
  };

  const editUserHandler = (user, userTypeVar: UserType) => {
    let activeUser = {};
    if (userTypeVar === "active") activeUser = getFormedUser(user);

    setSelectedUser(userTypeVar === "pending" ? user : activeUser);
    setUserType(userTypeVar);
    setUserModalType("edit");
    setUserModal(true);
  };

  const deleteUserHandler = (user, userTypeVar: UserType) => {
    let activeUser = {};
    if (userTypeVar === "active") activeUser = getFormedUser(user);

    setSelectedUser(userTypeVar === "pending" ? user : activeUser);
    setUserType(userTypeVar);
    setUserDeleteModal(true);
  };

  const doneHandler = () => {
    setLoading(true);
    setPageCount(0);
    getUsers(false, 1);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H4" className="ts-accent mb-6">
          {access === "agent" ? "لیست" : "مدیریت"} کارکنان کسب و کار {name || ""}
        </Text>

        {access !== "agent" && (
          <Text type="P4" className="ts-gray-05 mb-6">
            میتوانید با اضافه کردن شماره تلفن همراه همکار و کارمند خود، به او دسترسی به حساب کاربری کسب و کار را بدهید.
            بنابراین، استفاده همزمان از یک حساب کاربری برای شما امکانپذیر خواهد شد.
          </Text>
        )}
        {!loading && access !== "agent" && (
          <Button
            onClick={addUserHandler}
            icon={<FaUserPlus size={20} className="ts-accent -mt-1 ml-2" />}
            bcolor="blackTransparent"
            textType="H6"
            classNameContainer="w-full"
            className={`w-full rounded-xl h-12 mb-6`}
          >
            اضافه کردن کاربر جدید
          </Button>
        )}
        {loading ? (
          <div className="w-full">
            {[1, 2, 3, 4].map(() => {
              return (
                <Skeleton
                  style={{ height: "81.6px" }}
                  containerClassName="w-full"
                  className="w-full rounded-2xl mb-3"
                />
              );
            })}
          </div>
        ) : (
          <>
            {/* {ownerInfo.id && access !== "owner" && ( */}
            <UserCard
              name={ownerInfo.user.name || "---"}
              mobile={ownerInfo.user.phoneNumber || ownerInfo.user.username}
              id={ownerInfo.id}
              accessLevel={access}
              type="owner"
            />
            {/* )} */}
            {snap.usersInvitations.map((item: any) => {
              return (
                <UserCard
                  name={item.name || "---"}
                  mobile={item.mobile}
                  id={item.id}
                  type={item.status}
                  accessLevel={access}
                  onEdit={() => editUserHandler(item, "pending")}
                  onDelete={() => deleteUserHandler(item, "pending")}
                />
              );
            })}
            {snap.users.map((item: any) => {
              return (
                <UserCard
                  name={item.user.name || "---"}
                  mobile={item.user.phoneNumber || item.user.username}
                  id={item.id}
                  type={"accepted"}
                  accessLevel={access}
                  onEdit={() => editUserHandler(item, "active")}
                  onDelete={() => deleteUserHandler(item, "active")}
                />
              );
            })}
          </>
        )}

        {snap.isEndOfPage && !loading && (
          <div className="w-full flex justify-center">
            <Loader size="sm" />
          </div>
        )}
      </ContainerScroll>

      <AddOrEditUserModal
        isOpen={userModal}
        type={userModalType}
        user={selectedUser}
        userType={userType}
        workshop={id}
        onAddOrEditDone={doneHandler}
        onClose={() => setUserModal(false)}
        userLevel={access}
        url={{ id, name, access }}
      />

      <DeleteUserModal
        isOpen={userDeleteModal}
        data={selectedUser}
        userType={userType}
        onClose={() => setUserDeleteModal(false)}
        onEnd={doneHandler}
      />
    </>
  );
};

const ownerDefault = {
  accessLevel: "",
  id: "",
  user: { id: "", username: "", name: "", enabled: true, phoneNumber: "", phoneNumberVerified: true },
  userId: "",
};

export { ManageWorkshopStaffs as default };
