/* eslint-disable */
import { useObjectState } from "core";
import React, { useEffect, useState } from "react";
import { BottomModal, Button, InputField, Text } from "ui/components";
import { MessageQueuedNotificationModal } from "./MessageQueuedNotificationModal";

interface IInviteCustomersModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone?: (name) => void;
}

const InviteCustomersModal: React.FC<IInviteCustomersModalProps> = React.memo(({ isOpen, onClose, onDone }) => {
  const [info, setInfo] = useObjectState({
    contactName: "",
    mobile: "",
  });
  const { contactName, mobile } = info;

  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setInfo({
        contactName: "",
        mobile: "",
      });
    }
  }, [isOpen]);

  const addContactHandler = async () => {
    setLoading(true);
    // ApiInstance.sessions
    //   .inviteClient(info)
    //   .then((res) => {
    //     console.log(res);
    setConfirmModal(true);
    onClose();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //       useNotification("مشکلی رخ داده است. لطفا لحظاتی بعد مجددا تلاش کنید.", "error");
    //   })
    //   .finally(() => {
    setLoading(false);
    // });
  };

  return (
    <>
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full text-right">
          <Text type="H4" className="ts-accent mb-2">
            دعوت از مشتریان
          </Text>
          <Text type="P3" className="ts-gray-07 mb-6">
            با تکمیل فرم زیر یک پیام حاوی لینک ثبت نام در ویراژ از طرف شما، به شماره ای که وارد می کنید، ارسال می شود.
          </Text>

          <InputField
            label="نام و نام خانوادگی"
            value={contactName}
            onChange={(e) => setInfo({ contactName: e })}
            placeholder="نام و نام خانوادگی"
          />
          <InputField
            label="شماره تماس"
            inputType="number"
            value={mobile}
            onChange={(e) => {
              if (e.length <= 11) {
                setInfo({ mobile: e });
              }
            }}
            placeholder="09000000000"
          />

          <Button
            onClick={addContactHandler}
            loading={loading}
            disabled={contactName.length === 0 || mobile.length !== 11}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full h-12"
          >
            ارسال پیامک
          </Button>
        </div>
      </BottomModal>

      <MessageQueuedNotificationModal
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
        description="پیامک دعوت به مشتری ارسال شد. در صورت ثبت نام در ویراژ، شما قادر خواهید بود با این مشتری ارتباط داشته باشید."
        isBottom
      />
    </>
  );
});

export { InviteCustomersModal };
