/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { vState } from "core";
import moment from "moment";
import { ServiceStorage } from "..";

interface IDateFormat {
  showDates: string[];
  dates: string[];
}

export const tools = {
  isObject: (data) => {
    return data && typeof data === "object";
  },
  toObject(data) {
    return this.isObject(data) ? data : {};
  },
  scrollToTop: (ref) => {
    ref.current.scrollTo(0, 0);
  },

  /**
   * Find specific field error message from errorArray
   * @param {{field: String, errorMessage: String}[]} errorArray
   * @param {string} fieldName the field's name that you want to look for into errorArray
   * @return {string} field error message if found. otherwise return empty string
   */
  findErrorMessage: (errorArray, fieldName) => {
    const t = errorArray.find((x) => x.field === fieldName);
    if (t) {
      return t.errorMessage;
    } else {
      return "";
    }
  },
  /**
   * Get Validation model, and input data object then return result array that we can store it into errors Array. if there is no error, it will be empty
   * @param {Schema} validationModelSchema
   * @param {Object} inputObject object of values that contain field's name for each input
   * @return {{field: String, errorMessage: String}[]}
   */
  applyModelCheckValidation: (validationModelSchema, inputObject) => {
    let result: { field: string; errorMessage: string }[] = [];
    const checkResult = validationModelSchema.check(inputObject);
    for (let key in checkResult) {
      let value = checkResult[key];
      if (value.hasError) {
        result.push({ field: key, errorMessage: value.errorMessage });
      }
    }
    return result;
  },
  thousandSeparator: (number) => {
    if (number) {
      let n = parseFloat(number);
      return n.toLocaleString();
    } else {
      return number;
    }
  },
  convertFileCapacity: (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },
  getFormattedPlate: (firstSection, letter, secondSection, thirdSection) => {
    return `${digitsFaToEn(firstSection)}${letter}${digitsFaToEn(secondSection)}-${digitsFaToEn(thirdSection)}`;
  },
  setPlateNumber: (plateNumber) => {
    return `ایران ${plateNumber.substring(7, 9)} - ${plateNumber.substring(3, 6)} ${
      plateNumber.slice(2, 3) === "ا" ? "الف" : plateNumber.slice(2, 3)
    } ${plateNumber.slice(0, 2)}`;
  },
  isIOS: () => {
    // @ts-ignore
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  },
  getDateRange: (range: string): [string, string] => {
    let minDate;
    let maxDate;
    switch (range) {
      case "one-week":
        minDate = moment().toISOString();
        maxDate = moment().subtract(7, "days").toISOString();
        return [minDate, maxDate];
      case "one-month":
        minDate = moment().toISOString();
        maxDate = moment().subtract(1, "month").toISOString();
        return [minDate, maxDate];
      case "three-month":
        minDate = moment().toISOString();
        maxDate = moment().subtract(3, "months").toISOString();
        return [minDate, maxDate];
      case "six-month":
        minDate = moment().toISOString();
        maxDate = moment().subtract(6, "months").toISOString();
        return [minDate, maxDate];
      case "one-year":
        minDate = moment().toISOString();
        maxDate = moment().subtract(1, "year").toISOString();
        return [minDate, maxDate];
      default:
        minDate = moment().toISOString();
        maxDate = moment().subtract(7, "days").toISOString();
        return [minDate, maxDate];
    }
  },
  getChartDateRange: (range: string): IDateFormat => {
    let minDate;
    let formatted: any[] = [];
    let dates: any[] = [];
    switch (range) {
      case "per_day":
        minDate = moment().toISOString();
        let i = 0;
        while (i < 6) {
          const date = moment().subtract(i, "days").format("YYYY/MM/DD");
          const formattedDate = new Date(date).toLocaleDateString("fa-IR");
          formatted.push(formattedDate);
          dates.push(date);
          i++;
        }
        const weekResult = {
          showDates: formatted,
          dates: dates,
        };
        return weekResult;

      case "per_week":
        minDate = moment().toISOString();
        let j = 0;
        while (j < 3) {
          const date = moment().subtract(j, "months").format("YYYY/MM/DD");
          const formattedDate = new Date(date).toLocaleDateString("fa-IR", {
            month: "long",
            day: undefined,
            year: undefined,
          });
          formatted.push(formattedDate);
          dates.push(date);
          j++;
        }
        const monthResult = {
          showDates: formatted,
          dates: dates,
        };
        return monthResult;

      case "per_month":
        minDate = moment().toISOString();
        let k = 0;
        while (k < 12) {
          const date = moment().subtract(k, "months").format("YYYY/MM/DD");
          const formattedDate = new Date(date).toLocaleDateString("fa-IR", {
            year: "numeric",
            month: "long",
            day: undefined,
          });
          formatted.push(formattedDate);
          dates.push(date);
          k++;
        }
        const yearResult = {
          showDates: formatted,
          dates: dates,
        };
        console.log(yearResult);

        return yearResult;
      default:
        return {
          showDates: [],
          dates: [],
        };
    }
  },
  setNewActiveWorkshop: (data: any) => {
    const firstData = data[0];
    vState.selectedWorkshop = firstData.id || "";
    vState.activeWorkshop = firstData || null;
    vState.activeFeatures =
      firstData.categories.length !== 0
        ? firstData.categories
            .filter((item) => item.features !== null)
            .map((item) => item.features)
            .flat()
        : [];
    ServiceStorage.setActiveWorkshop(firstData.id || "");
  },
  setActiveWorkshop: (data: any) => {
    const activeWorkshop = ServiceStorage.getActiveWorkshop();
    if (activeWorkshop) {
      const index = data.findIndex((item) => item.id === activeWorkshop);
      if (index !== -1) {
        const indexedData = data[index];
        vState.activeWorkshop = indexedData || null;
        vState.selectedWorkshop = indexedData.id || "";
        const features =
          indexedData.categories.length !== 0
            ? indexedData.categories
                .filter((item) => item.features !== null)
                .map((item) => item.features)
                .flat()
            : [];
        vState.activeFeatures = features;
        ServiceStorage.setActiveWorkshop(indexedData.id || "");
      } else {
        ServiceStorage.removeActiveWorkshop();
        tools.setNewActiveWorkshop(data);
      }
    } else {
      ServiceStorage.removeActiveWorkshop();
      tools.setNewActiveWorkshop(data);
    }
  },
  convertDateToPersianDate: (date: string, withTime: boolean = false, withWeekDay: boolean = false) => {
    return `${
      withWeekDay
        ? new Date(date).toLocaleDateString("fa-IR", {
            weekday: "long",
          })
        : ""
    } ${new Date(date).toLocaleDateString("fa-IR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })} ${
      withTime
        ? `ساعت ${new Date(date).toLocaleTimeString("fa-IR", {
            hour: "numeric",
            minute: "numeric",
          })}`
        : ""
    }`;
  },
  convertDateToIranTime: (date: string) => {
    return date
      ? `${new Date(date).toLocaleTimeString("fa-IR", {
          hour: "numeric",
          minute: "numeric",
        })}`
      : "";
  },
  getSimpleDate: (date: string, withTime?: boolean) => {
    return date
      ? `${new Date(date).toLocaleDateString("fa-IR")}${
          withTime
            ? ` - ${new Date(date).toLocaleTimeString("fa-IR", {
                hour: "numeric",
                minute: "numeric",
              })}`
            : ""
        }`
      : "";
  },
  redirectToPaymentPage: (payId: string) => {
    const environment = window.location.host.includes("virazh") ? "virazh" : "designersonline";
    const url = `https://payment.${environment}.ir/${payId}${environment === "designersonline" ? `?testing=true` : ""}`;
    console.log(url);

    window.location.assign(url);
  },
};
