/* eslint-disable */
import NoResult from "assets/images/clients-no-results.svg";
import { ApiInstance, ConfigLink, tools, useChangeTitle, useFetchQueryString, useQueryString, vState } from "core";
import { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { BsChatSquareTextFill, BsExclamationCircleFill } from "react-icons/bs";
import { HiClock, HiPencilAlt } from "react-icons/hi";
import { IoIosCheckmarkCircle, IoMdCall } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import {
  Button,
  ContainerScroll,
  PremiumNoticeModal,
  SuccessfulSubscription,
  Text,
  useNotification,
} from "ui/components";
import { useSnapshot } from "valtio";
import { AddEditToClientListModal } from "./components";

interface IQueryStringTransfer {
  subPayed?: string;
}

type SORT_TYPE = "DATE" | "NAME" | null;

const ClientDetail = ({}) => {
  const metaTags = useChangeTitle("ویراژ - جزئیات مشتری");
  let { subPayed } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  let { client_id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    id: "",
    contactName: "",
    mobile: "",
    userProfileId: "",
    registered: false,
  });
  const [sessions, setSessions] = useState([]);
  const [sessionPage, setSessionPage] = useState(1);
  const [sessionCount, setSessionCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [addEditClientModal, setAddEditClientModal] = useState(false);
  const [premiumModal, setPremiumModal] = useState(false);
  const [successfulSubModal, setSuccessfulSubModal] = useState(false);

  const [sortUsers, setSortUsers] = useState<SORT_TYPE>("NAME");

  const getInfo = async () => {
    await ApiInstance.sessions
      .getClientDetail(client_id || "")
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error");
      })
      .finally(() => {
        getSession();
      });
  };

  const getSession = async () => {
    await ApiInstance.sessions
      .getClientSession(client_id || "", sessionPage)
      .then((res) => {
        console.log(res);
        setSessions(res.data.data);
        setSessionPage((prev) => prev + 1);
        setSessionCount(res.data.meta.total);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    console.log("id", client_id);

    getInfo();

    if (subPayed && subPayed === "1") {
      setSuccessfulSubModal(true);
    }
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && sessionCount > sessions.length && !loading) {
      getSession();
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortUsers(value);
    setLoading(true);
    getInfo();
  };

  const historyClickHandler = (singleSession) => {
    navigate({
      pathname: ConfigLink.clientServiceHistory.replace(":client_id", client_id || ""),
      search: useQueryString({ id: singleSession.id }),
    });
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب الفبا",
      value: "NAME",
    },
  ];

  const callHandler = () => {
    window.open(`tel:${data.mobile}`, "_self");
  };

  const smsHandler = () => {
    window.open(`sms:/${data.mobile}`, "_self");
  };

  const checkPremium = (callBack: () => void) => {
    if (snap.userInfo.premium) {
      callBack();
    } else {
      if (!data.mobile?.includes("*")) {
        callBack();
      } else {
        setPremiumModal(true);
      }
    }
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={80} className="">
        <Text type="H4" className="ts-accent">
          جزییات
        </Text>

        <div className="w-full bg-white rounded-xl ts-shadow p-4 mt-3 mb-4 flex flex-col justify-start items-start">
          <span className="w-full flex justify-between items-center mb-4">
            <span className="flex justify-center items-center">
              <Text type="H5" className="ts-accent">
                اطلاعات مشتری
              </Text>
              {data.registered && <IoIosCheckmarkCircle size={20} className="ts-success mr-2 -mt-px" />}
            </span>
            <div onClick={() => setAddEditClientModal(true)} className="flex items-center cursor-pointer">
              <HiPencilAlt size={20} className="ts-primary ml-1 -mt-0.5" />
              <Text type="H6" className="ts-primary">
                ویرایش
              </Text>
            </div>
          </span>
          <span className="flex justify-center items-center mb-3">
            <Text type="H6" className="ts-accent w-28 ml-0.5">
              نام ونام خانوادگی
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              <Text type="P4" className="ts-accent">
                {data.contactName || "---"}
              </Text>
            )}
          </span>
          <span className="flex justify-center items-center mb-4">
            <Text type="H6" className="ts-accent w-28 ml-0.5">
              شماره تماس
            </Text>
            {loading ? (
              <Skeleton className="w-20" />
            ) : (
              <Text type="P4" className="ts-accent ts-reverse-direction">
                {data.mobile || "---"}
              </Text>
            )}
          </span>

          <span className="w-full flex justify-between items-center gap-2">
            <Button
              disabled={!Boolean(data.mobile) || data.mobile?.includes("*")}
              onClick={() => checkPremium(callHandler)}
              bcolor="gray"
              icon={<IoMdCall size={21} className="-rotate-90" />}
              classNameContainer="w-full ts-primary"
              className="w-full h-10 ts-primary"
            >
              تماس
            </Button>
            <Button
              disabled={!Boolean(data.mobile) || data.mobile?.includes("*")}
              onClick={() => checkPremium(smsHandler)}
              bcolor="gray"
              icon={<BsChatSquareTextFill size={21} />}
              classNameContainer="w-full ts-primary"
              className="w-full h-10 ts-primary"
            >
              پیامک
            </Button>
            {/* <Button
                  bcolor="gray"
                  icon={<BsFillChatDotsFill />}
                  classNameContainer="w-full ts-primary"
                  className="w-full h-10 ts-primary"
                >
                  چت
                </Button> */}
          </span>
          {(data.mobile?.includes("*") || !data.registered) && !loading && (
            <span className="w-full flex justify-start items-center mt-4">
              {data.mobile?.includes("*") ? (
                <>
                  <BsExclamationCircleFill size={14} className="ts-gray-04 -mt-0.5" />
                  <Text type="P5" className="ts-gray-04 mr-1">
                    این مشتری شماره خود را مخفی کرده است.
                  </Text>
                </>
              ) : (
                <>
                  <HiClock size={14} className="ts-info -mt-0.5" />
                  <Text type="P5" className="ts-info mr-1">
                    این مشتری هنوز در ویراژ ثبت نام نکرده است.
                  </Text>
                </>
              )}
            </span>
          )}
        </div>

        <div className="w-full flex justify-between items-center mb-5 mt-6">
          {loading ? (
            <Skeleton className="w-32" />
          ) : (
            <Text type="H5" className="ts-accent">
              {sessions.length} تاریخچه یادآور
            </Text>
          )}

          {/* <SelectPicker
            size="lg"
            data={sortData}
            value={sortUsers}
            disabled={loading}
            cleanable={false}
            onChange={(value: any) => sortChangeHandler(value)}
            searchable={false}
            placeholder="مرتب سازی"
            className={`w-36 h-12 outline-none rounded-xl`}
          /> */}
        </div>
        {loading ? (
          [1, 2, 3, 4, 5].map((item) => {
            return (
              <Skeleton style={{ height: "72px" }} containerClassName="w-full" className="w-full rounded-2xl mb-2" />
            );
          })
        ) : sessions.length === 0 ? (
          <div className="w-full flex flex-col justify-center items-center mt-12">
            <img src={NoResult} alt="no-result" />
            <Text type="P4" className="ts-accent mt-6">
              تاریخچه ای برای این کاربر ثبت نشده است.
            </Text>
          </div>
        ) : (
          sessions.map((item: any) => {
            return (
              <div
                key={item.id}
                style={{ height: "72px" }}
                onClick={() => historyClickHandler(item)}
                className={`w-full bg-white cursor-pointer flex justify-between items-center ts-shadow rounded-2xl p-3 mb-2`}
              >
                <div className="w-11/12 flex flex-col justify-start items-start">
                  <Text type="H6" className="ts-accent truncate mb-2">
                    {item.plate ? (item.plate.car ? item.plate.car.name : item.plate.carName || "---") : "---"}
                  </Text>
                  {/* <Text type="P4" className="ts-accent truncate mb-2 w-99/100">
                    {item.reminders.length === 0 ? "---" : item.reminders.map((rem) => rem.service.name).join("، ")}
                  </Text> */}
                  <Text type="P4" className="ts-gray-06 truncate">
                    {tools.convertDateToPersianDate(item.updatedAt, false, true)}
                  </Text>
                </div>
                <BiChevronLeft size={24} className="ts-accent" />
              </div>
            );
          })
        )}
        {snap.isEndOfPage && !loading && sessions.length < sessionCount && (
          <div className="w-full flex justify-center">
            <Loader size="sm" />
          </div>
        )}
      </ContainerScroll>

      <AddEditToClientListModal
        isOpen={addEditClientModal}
        onClose={() => setAddEditClientModal(false)}
        onDone={(name) =>
          setData((prev) => {
            return { ...prev, contactName: name };
          })
        }
        data={data}
        editName
        type={"edit"}
      />
      <PremiumNoticeModal isOpen={premiumModal} onClose={() => setPremiumModal(false)} />
      <SuccessfulSubscription
        isOpen={successfulSubModal}
        onClose={() => setSuccessfulSubModal(false)}
        newUrl={`${ConfigLink.clientDetail.replace(":client_id", client_id || "")}`}
      />
    </>
  );
};

export { ClientDetail as default };
