/* eslint-disable */

const ConfigLink = {
  setParam: (url: string, paramName: string, paramValue: string) => {
    return url.replace(paramName, paramValue);
  },
  //Storybook
  storybook: "/storybook",
  //Page Not Found
  pageNotFound: "/page-not-found",

  //account
  profile: "/profile",
  accountSetting: "/profile/account-setting",
  userSetting: "/profile/user-setting",
  contactUs: "/profile/contact",
  manageWorkshops: "/profile/workshops",
  workshopPanel: "/profile/workshops/:id",
  manageWorkshopStaffs: "/profile/workshops/:id/manage-staffs",
  editWorkshop: "/profile/workshops/:id/edit",
  viewWorkshop: "/profile/workshops/:id/view",
  workshopBulkSmsHistory: "/profile/workshops/bulk-history",
  inviteUsers: "/profile/invite",
  payments: "/profile/payments",
  wallet: "/profile/wallet",

  //customers list
  workshopClients: "/clients",
  clientDetail: "/clients/:client_id",
  clientServiceHistory: "/clients/:client_id/history",
  clientMessageCheckout: "/clients/checkout/:id",
  clientsBulkMessageHistory: "/clients/payments/history",
  bulkSmsDetail: "/clients/payment/history/:id",

  myPartRequest: "/my-part-requests",
  myPartRequestInfo: "/my-part-requests/:id",

  registerNewUser: "/new-user/invitation",

  //dashboard
  homePage: "/",
  dashboard: "/dashboard",
  activityReport: "/activity-report",
  admission: "/cars-admission",
  registeredCars: "/registered-cars",
  carStatus: "/car-status/:id",

  //users-part-requests
  usersPartRequests: "/users-part-requests",
  usersPartRequestDetail: "/users-part-requests/request-detail/:id",
  myPartsActivity: "/requests-list/my-activity",

  //login-register
  login: "/sign-up",

  //introductions
  intro: "/intro",
  completeInvitation: "/complete-invite",
  completeAccount: "/complete-account",

  //workspace
  createWorkspaceStepOne: "/create-workspace/step-one",
  createWorkspaceStepTwo: "/create-workspace/step-two",
  selectLocation: "/create-workspace/add-location",
  welcome: "/create-workspace/welcome",

  support: "/support",

  // shop
  shop: "/shop",
  myShopPartDetail: "/shop/part-detail",
  allShop: "/all-products",

  //searchWorkshops
  searchWorkshops: "/search-workshops",
  workshopInfo: "/search-workshops/workshop/info",

  //subscription
  subInfo: "/subscription/premium-sub",
  compareSub: "/subscription/comparison",
  subSelect: "/subscription/activate",
  subCheckout: "/subscription/checkout",

  paymentStatus: "/payment/callback",
  redirect: "/redirect",
};
export { ConfigLink };
